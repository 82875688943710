import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { fnModalAlert } from '@/components/common/modal/alert'
import { CommonFunction } from '@/common/CommonFunction'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    partyNo: route.query.partyNo,
    party: {},
    conflict: {},
    shareInfo: '',
    approval: false,
    leader: {},
    categoryNames: proxy.$_.keyBy(proxy.$ConstCode.CATEGORY2_VALUE, 'id'),
    conflictStatuses: proxy.$_.keyBy(
      proxy.$ConstCode.PARTY_CONFLICT_STATUS,
      'value'
    ),
    viewResOptions: computed(() =>
      proxy.$Util.isEmpty(state.party)
        ? false
        : ['resKakao', 'resPhone', 'resEmail'].some(o => state.party[o])
    ),
    totalPaymentAmt: computed(() => {
      let pamt = 0
      if (proxy.$Util.isEmpty(state.party)) return pamt
      pamt = Number(state.party.paymentAmt)
      if (Number(state.party.usePoint) > 0) {
        pamt -= Number(state.party.usePoint)
      }
      return pamt
    }),
    isShowRefund: computed(() => {
      // 현재 취소/환불 신청가능한 상태인지 확인
      if (state.party.statusNm === '종료') return false
      if (proxy.$Util.isEmpty(proxy.$_.find(state.conflict, { status: '0' })))
        return true
      return false
    }),
    isShowCancelRefund: computed(() => {
      // 현재 분쟁취소가능한 상태인지 확인
      if (state.party.statusNm === '종료') return false
      if (!proxy.$Util.isEmpty(proxy.$_.find(state.conflict, { status: '0' })))
        return true
      return false
    }),
    simpleChangeMind: computed(() => {
      if (state.party.shareYn === 'N' && state.party.shareConfirm === '1') {
        return false
      } else {
        return true
      }
    })
  })
  const { copyCliboard } = CommonFunction(state)

  const fnDetail = async () => {
    const params = {}
    params.partyNo = state.partyNo
    const res = await proxy.$MyPageSvc.postParticipatePartyDetail(params)
    if (res.resultCode === '0000') {
      state.party = res.party
      state.conflict = res.conflict
      state.shareInfo = res.shareInfo
      state.approval = res.approval
      state.leader = res.leader
    } else {
      alert(res.resultMsg)
    }
  }
  const fnWizzTalk = async () => {
    const params = {}
    params.partyNo = state.partyNo
    const res = await proxy.$ChatSvc.postGetChatroomId(params)
    if (res.resultCode === '0000') {
      params.chatroomId = res.chatroomId
      await router.push({
        path: '/wizz-talk',
        query: params
      })
    } else {
      alert(res.resultMsg)
    }
  }
  const fnRefund = async (isEdit, item) => {
    let data = {}
    if (isEdit === proxy.$ConstCode.BOOLEAN_VALUE.TRUE) {
      data.seq = item.seq
      data.option = item.option
      data.msg = item.msg
      data.isEdit = proxy.$ConstCode.BOOLEAN_VALUE.TRUE
    }
    data.simpleChangeMind = state.simpleChangeMind
    const payload = {
      component: proxy.$modalAlertNames.PARTY_REFUND,
      data: data,
      callback: async () => {
        await fnDetail()
      }
    }
    await fnModalAlert(payload)
  }
  const fnLabelStatus = status => {
    return state.conflictStatuses[Number(status)].name
  }
  const cancelRefund = async () => {
    if (!confirm('분쟁취소하시겠습니까?')) return
    const item = proxy.$_.find(state.conflict, { status: '0' })
    const params = {}
    params.seq = item.seq
    const res = await proxy.$PartySvc.postPartyCancelRefundDelete(params)
    if (res.resultCode === '0000') {
      alert(
        '취소 환불신청이 취소되었습니다. 일시중지일로부터 정상 서비스로 전환합니다.'
      )
      await fnDetail()
    } else {
      alert(res.resultMsg)
    }
  }
  const fnApply = async () => {
    if (!confirm('결제승인하시겠습니까?')) return
    const params = {}
    params.partyNo = state.partyNo
    const res = await proxy.$CashSvc.postPartyPaymentApproval(params)
    if (res.resultCode === '0000') {
      alert('결제승인되었습니다.')
    } else {
      alert(res.resultMsg)
    }
    await fnDetail()
  }
  const fnDelete = async () => {
    const params = {}
    params.partyNo = state.partyNo
    const res = await proxy.$MyPageSvc.postParticipatePartyDelete(params)
    if (res.resultCode === '0000') {
      alert('삭제되었습니다.')
      await router.go(-1)
    } else {
      alert(res.resultMsg)
    }
  }
  const fnMsg = item => {
    if (item.card === '위즈캐시') {
      if (Number(item.usePoint) > 0) {
        return `파티장 (자동) 승인에 따라, 고객님께서 ${proxy
          .$dayjs(item.regDt)
          .format(
            'YYYY년 MM.DD일'
          )} 문의하신 환불 요청 건은 확인 결과 취소 처리 하였습니다.
        총 ${proxy.$Util.formatNumber2(item.paymentAmt, {
          unit: '캐시'
        })} 및 ${proxy.$Util.formatNumber2(item.usePoint, {
          unit: '원'
        })}가 환급되었습니다.`
      } else {
        return `파티장 (자동) 승인에 따라, 고객님께서 ${proxy
          .$dayjs(item.regDt)
          .format(
            'YYYY년 MM.DD일'
          )} 문의하신 환불 요청 건은 확인 결과 취소 처리 하였습니다.
        총 ${proxy.$Util.formatNumber2(item.paymentAmt, {
          unit: '캐시'
        })}가 환급되었습니다.`
      }
    } else {
      // 카드결제
      if (Number(item.usePoint) > 0) {
        return `파티장 (자동) 승인에 따라, 고객님께서 ${proxy
          .$dayjs(item.regDt)
          .format(
            'YYYY년 MM.DD일'
          )} 문의하신 환불 요청 건은 확인 결과 취소 처리 하였습니다.
        카드결제 ${item.card} 결제내역이 ${proxy.$Util.formatNumber2(
          item.paymentAmt,
          {
            unit: '원'
          }
        )} (부분) 취소되었으며, ${proxy.$Util.formatNumber2(item.usePoint, {
          unit: '포인트'
        })}가 환급되었습니다.`
      } else {
        return `파티장 (자동) 승인에 따라, 고객님께서 ${proxy
          .$dayjs(item.regDt)
          .format(
            'YYYY년 MM.DD일'
          )} 문의하신 환불 요청 건은 확인 결과 취소 처리 하였습니다.
        카드결제 ${item.card} 결제내역이 ${proxy.$Util.formatNumber2(
          item.paymentAmt,
          {
            unit: '원'
          }
        )} (부분) 취소되었습니다.`
      }
    }
  }
  const fnModalAddInfo = async () => {
    const payload = {
      component: proxy.$modalAlertNames.PARTY_ADD_INFO,
      data: state.party,
      callback: async reply => {
        await fnSaveReqOption(reply)
      }
    }
    await fnModalAlert(payload)
  }
  const fnSaveReqOption = async reply => {
    const params = {}
    params.partyNo = state.partyNo
    params.reply = reply
    params.memberSeq = state.party.memberSeq
    let res
    if (proxy.$Util.isEmpty(state.party.reply)) {
      res = await proxy.$MyPageSvc.postPartyOptionRegister(params)
    } else {
      res = await proxy.$MyPageSvc.postPartyOptionUpdate(params)
    }
    if (res.resultCode === '0000') {
      state.party.reply = reply
    } else {
      alert(res.resultMsg)
    }
  }

  const fnUpdateReqOption = async resOptions => {
    const params = {
      partyNo: state.partyNo,
      memberSeq: state.party.memberSeq,
      ...resOptions
    }
    const res = await proxy.$MyPageSvc.postParticipatePartyUpdateResOption(
      params
    )
    if (res.resultCode === '0000') {
      await fnDetail()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnModalShareInfoCheck = async () => {
    const data = {
      partyNo: state.partyNo,
      memberSeq: state.party.memberSeq
    }
    const payload = {
      component: proxy.$modalAlertNames.SHARE_INFO_CHECK,
      data,
      callback: async () => {
        await fnDetail()
      }
    }

    await fnModalAlert(payload)
  }
  const fnModalAlertSellerProfile = () => {
    const payload = {
      component: proxy.$modalAlertNames.SELLER_PROFILE,
      data: {
        userNm: state.leader.userNm,
        userLvNm: state.leader.userLvNm,
        sellerLv: state.leader.sellerLv,
        profile: state.leader.profile,
        productNo: route.query.partyNo,
        productType: 'PARTY',
        userData: state.userData,
        memberSeq: 'TRUE'
      },
      callback: () => {}
    }
    fnModalAlert(payload)
  }

  const fnRequestExtensionCancel = async () => {
    if (
      !confirm(
        '자동연장 설정을 해제 하시겠습니까?\n해지 후 재설정은 불가능합니다.'
      )
    )
      return false
    const res = await proxy.$MyPageSvc.postJoinPartyAutoExtensionCancel({
      partyNo: route.query.partyNo
    })
    let resultMsg = res.resultMsg
    if (res.resultCode === '0000') {
      resultMsg =
        '해제되었습니다.\n이용기간 만료 후 해당파티 참여가 종료됩니다.'
      // resultMsg = `파티 연장요청이 취소되었습니다.\n${proxy
      //   .$dayjs(item.useDt)
      //   .format('YYYY년 MM월DD일')} 파티가 종료됩니다.`
      await fnDetail()
    }
    alert(resultMsg)
  }

  const fnReqOptionEdit = async () => {
    let data = {
      resKakao: state.party.resKakao,
      resPhone: state.party.resPhone,
      resEmail: state.party.resEmail
    }
    const resOptions = Object.keys(data).reduce(
      (pv, cv) => (data[cv] ? { ...pv, [cv]: true } : { ...pv }),
      {}
    )

    data.resOptions = resOptions
    data.type = 'PARTY'

    const payload = {
      component: proxy.$modalAlertNames.REQ_OPTION_EDIT,
      data,
      callback: async resOptions => {
        await fnUpdateReqOption(resOptions)
      }
    }
    await fnModalAlert(payload)
  }

  const init = async () => {
    await fnDetail()
  }
  init()
  return {
    ...toRefs(state),
    fnWizzTalk,
    fnRefund,
    fnLabelStatus,
    cancelRefund,
    fnApply,
    fnDelete,
    fnMsg,
    fnModalAddInfo,
    fnModalShareInfoCheck,
    copyCliboard,
    fnModalAlertSellerProfile,
    fnRequestExtensionCancel,
    fnReqOptionEdit
  }
}
