<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner" v-if="!$Util.isEmpty(party)">
        <div class="page_con">
          <!--          <div class="con_ttl">-->
          <!--            <span>파티원 등록 정보</span>-->
          <!--          </div>-->
          <div class="detail_top">
            <div class="party_tag big center">
              <!--'상세페이지에서: big'-->
              <div class="left">
                <div
                  class="pt_tag logo"
                  :class="{ sl: party.acntTransferYn === 'N' }"
                  style="--pt-txt-color: #fff;"
                  :style="{
                    '--pt-bg-color': $ConstCode.getCategoryById(
                      party.categoryId
                    ).color
                  }"
                >
                  {{ categoryNames[party.categoryId].text }}
                </div>
                <div
                  class="pt_tag normal nb blue"
                  v-if="party.acntTransferYn === 'Y'"
                >
                  양도
                </div>
              </div>
            </div>
            <div class="party_ttl">{{ party.title }}</div>
            <div
              class="progress"
              :class="{
                pause:
                  party.statusNm === '일시정지' ||
                  party.statusNm === '취소/환불' ||
                  party.statusNm === '종료'
              }"
              v-if="party.acntTransferYn === 'N'"
            >
              <!--'일시정지: pause'-->
              <span class="percent">{{
                $Util.formatNumber2(party.progressRate, { unit: '%' })
              }}</span>
              <div
                class="bar"
                :style="{
                  '--progress-percent': !$Util.isEmpty(party.progressRate)
                    ? `${party.progressRate}%`
                    : '0%'
                }"
              ></div>
            </div>
          </div>
          <div class="lump">
            <div class="table_type01">
              <dl>
                <dt>사용일</dt>
                <dd>
                  {{ $Util.formatNumber2(party.usePeriod, { unit: '일' }) }}
                </dd>
              </dl>
              <dl>
                <dt>적립포인트</dt>
                <dd>
                  {{
                    Number(party.savePoint) === 0 ||
                    $Util.isEmpty(party.savePoint)
                      ? '이벤트없음'
                      : $Util.formatNumber2(party.savePoint, { unit: 'P' })
                  }}
                </dd>
              </dl>
              <dl>
                <dt>판매가</dt>
                <dd v-if="party.acntTransferYn === 'N'">
                  {{ $Util.formatNumber2(party.partyAmt, { unit: '원' })
                  }}<br />
                  <span class="daily">{{
                    `(${$Util.formatNumber2(party.dailyPrice, {
                      unit: '원'
                    })})`
                  }}</span>
                </dd>
                <dd v-else>
                  {{ $Util.formatNumber2(party.dailyPrice, { unit: '원' }) }}
                </dd>
              </dl>
              <dl>
                <dt>수수료</dt>
                <dd>{{ $Util.formatNumber2(party.fee, { unit: '원' }) }}</dd>
              </dl>
            </div>
            <div class="table_type02">
              <dl>
                <dt>이용 기간</dt>
                <dd class="space_between">
                  <span>{{
                    party.unlimitedCheckYn === 'N'
                      ? `${$dayjs(party.regDt).format('YYYY.MM.DD')} ~ ${$dayjs(
                          party.useDt
                        )
                          .subtract(1, 'd')
                          .format('YYYY.MM.DD')}`
                      : '무제한 또는 기간 별도 표시'
                  }}</span>
                  <span
                    v-if="
                      $Util.isNumeric(party.statusNm) &&
                        party.unlimitedCheckYn === 'N'
                    "
                    ><span class="color">{{ party.statusNm }}</span
                    >{{
                      $Util.isNumeric(party.statusNm) &&
                      party.autoExtension === 'Y'
                        ? '일 후 자동연장'
                        : $Util.isNumeric(party.statusNm) &&
                          party.autoExtension === 'N'
                        ? '일 남음'
                        : ''
                    }}</span
                  >
                  <span v-else>
                    <span
                      class="color"
                      :class="[
                        {
                          grey:
                            party.statusNm === '취소/환불' ||
                            party.statusNm === '종료'
                        },
                        {
                          error: party.statusNm === '일시정지'
                        }
                      ]"
                      >{{
                        party.statusNm === '일시정지' ? '일시정지' : '종료'
                      }}</span
                    ></span
                  >
                </dd>
              </dl>
              <dl>
                <dt>결제자 정보</dt>
                <dd class="buyer_info">
                  <span class="name">{{ party.nick }}</span>
                  <!--                  <span>{{ party.name }}</span>-->
                  <div
                    class="sv_tag"
                    :class="[
                      {
                        kakao:
                          party.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.KAKAO.enName
                      },
                      {
                        naver:
                          party.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.NAVER.enName
                      },
                      {
                        google:
                          party.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.GOOGLE.enName
                      },
                      {
                        apple:
                          party.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.APPLE.enName
                      }
                    ]"
                    v-if="party.loginType !== 'wizzney'"
                  >
                    {{ party.loginType }}
                  </div>
                  <!--'카카오: kakao', '네이버: naver', '구글: google'-->
                </dd>
              </dl>
              <dl>
                <dt>총 결제액</dt>
                <dd>
                  <span class="sec_color">{{
                    $Util.formatNumber2(totalPaymentAmt, { unit: '' })
                  }}</span>
                  원<span
                    >&nbsp;&nbsp;({{ party.usePoint }}P 사용 / 캐시 할인
                    {{ party.discount }}원)</span
                  >
                </dd>
                <!-- <dd class="space_between">
                  <div
                    v-if="
                      !$Util.isEmpty(party.usePoint) &&
                        Number(party.usePoint) > 0
                    "
                  >
                    {{
                      `${$Util.formatNumber2(
                        Number(party.partyAmt) + Number(party.fee),
                        { unit: '' }
                      )}${
                        !$Util.isEmpty(party.usePoint) &&
                        Number(party.usePoint) > 0
                          ? ' - '
                          : ''
                      }`
                    }}<span
                      class="color"
                      v-if="
                        !$Util.isEmpty(party.usePoint) &&
                          Number(party.usePoint) > 0
                      "
                      >{{
                        $Util.formatNumber2(party.usePoint, {
                          unit: 'P'
                        })
                      }}</span
                    >
                  </div>
                  <div
                    class="total"
                    :class="{
                      hide: !(
                        !$Util.isEmpty(party.usePoint) &&
                        Number(party.usePoint) > 0
                      )
                    }"
                  >
                    <span class="sec_color">{{
                      $Util.formatNumber2(totalPaymentAmt, {
                        unit: ''
                      })
                    }}</span>
                    원
                  </div>
                </dd> -->
              </dl>
              <dl>
                <dt>결제 방법</dt>
                <dd>{{ party.card }}</dd>
              </dl>
              <dl>
                <dt>결제일</dt>
                <dd>{{ $dayjs(party.paymentDt).format('YYYY.MM.DD') }}</dd>
              </dl>
            </div>
          </div>
          <div class="lump" v-if="party.acntTransferYn === 'N'">
            <div class="table_type02">
              <dl>
                <dt>자동연장 설정</dt>
                <dd style="display: flex; align-items: center;">
                  <div>
                    {{
                      party.autoExtension === 'Y' &&
                      party.autoExtensionType === '1'
                        ? '자동연장'
                        : '-'
                    }}
                  </div>
                  <button
                    v-if="
                      party.status == '0' && party.autoExtensionType === '1'
                    "
                    class="btn_wizzcash"
                  >
                    <div @click="fnRequestExtensionCancel" class="btn_text">
                      연장취소
                    </div>
                  </button>
                </dd>
              </dl>
            </div>
          </div>
          <div v-if="viewResOptions" class="lump bo_zero">
            <div class="form">
              <div class="form_ttl" style="color: #999; font-weight: 500;">
                파티원 요청정보
              </div>
              <ul class="form_ul">
                <li v-if="party.resKakao" class="form_li">
                  <div class="form_ttl essen">카카오톡 ID</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="카카오톡 ID를 입력해주세요"
                        v-model="party.resKakao"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
                <li v-if="party.resPhone" class="form_li">
                  <div class="form_ttl essen">휴대폰번호</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="휴대폰번호를 입력해 주세요"
                        v-model="party.resPhone"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
                <li v-if="party.resEmail" class="form_li">
                  <div class="form_ttl essen">이메일</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="이메일을 입력해주세요"
                        v-model="party.resEmail"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
              </ul>
              <div v-if="party.status != '2' || '3'" class="btn_wrap">
                <button class="btn mt10" @click="fnReqOptionEdit">
                  파티원 요청정보 수정
                </button>
              </div>
            </div>
          </div>
          <div class="lump">
            <div class="table_type02">
              <dl>
                <dt>판매자</dt>
                <dd>
                  <div class="flex">
                    <div
                      class="prof"
                      :class="[
                        {
                          off:
                            leader.userLvNm === $ConstCode.LEVEL_TYPE['0'].name
                        },
                        {
                          vip:
                            leader.sellerLv >= 3
                        },
                        {
                          nonebg:
                            !$Util.isEmpty(leader.profile) &&
                            leader.profile.indexOf('/null') < 0
                        }
                      ]"
                    >
                      <!-- 프로필이미지 지정시 nonebg 추가 -->
                      <div class="img_area">
                        <img
                          :src="leader.profile"
                          alt=""
                          v-if="
                            !$Util.isEmpty(leader.profile) &&
                              leader.profile.indexOf('/null') < 0
                          "
                        />
                      </div>
                    </div>
                    <div class="nickname">
                      {{ leader.userNm }}
                    </div>
                    <div @click="fnModalAlertSellerProfile" class="right">
                      판매자 정보 보기
                    </div>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="lump" style="border: 0;">
            <div class="table_type02">
              <dl>
                <dt>
                  파티 소개 글 및 주의 사항
                  <span
                    class="table_date"
                    :class="{ new: Number(party.hasPartyUpdateBadge) === 1 }"
                    >{{ $dayjs(party.regDt).format('YYYY.MM.DD') }}</span
                  >
                </dt>
                <dd class="editor">
                  <span>{{ party.precautions }}</span>
                </dd>
              </dl>
            </div>
          </div>
          <div
            class="lump bo_zero"
            v-if="party.shareYn === 'N' && party.status != '3'"
          >
            <div class="table_type02">
              <dl>
                <dt>
                  공유 계정
                  <span
                    v-if="party.shareConfirm === '1'"
                    class="table_date"
                    :class="{ new: Number(party.hasShareBadge) === 1 }"
                    >{{ $dayjs(shareInfo.regDt).format('YYYY.MM.DD') }}
                  </span>
                </dt>
                <dd>
                  <div class="share_info" v-if="!shareInfo">
                    <div class="btn_wrap">
                      <button class="btn" @click="fnModalShareInfoCheck">
                        계정 정보 확인
                      </button>
                    </div>
                  </div>
                  <div class="share_info" v-else>
                    <dl>
                      <dt>ID</dt>
                      <dd
                        class="copy_button"
                        @click="copyCliboard(shareInfo.shareId, '아이디')"
                      >
                        {{ shareInfo.shareId }}
                      </dd>
                    </dl>
                    <dl>
                      <dt>PW</dt>
                      <dd
                        class="copy_button"
                        @click="copyCliboard(shareInfo.sharePw, '비밀번호')"
                      >
                        {{ shareInfo.sharePw }}
                      </dd>
                    </dl>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <!--         TODO://파티장이 추가정보 요청했을 때 떠야함 -->
          <div class="lump bo_zero" v-if="party.optionYn === 'Y'">
            <div class="table_type02">
              <dl>
                <dt>
                  정보 입력
                  <span class="table_date">{{
                    $dayjs(party.optionDt).format('YYYY.MM.DD')
                  }}</span>
                </dt>
                <dd class="editor">
                  <div>
                    <!--붙여쓰기-->{{ party.msg
                    }}<!--붙여쓰기-->
                  </div>
                  <div
                    style="white-space: inherit; margin-top: 1rem;"
                    v-show="!$Util.isEmpty(party.reply)"
                  >
                    <label class="input">
                      <input type="text" v-model="party.reply" readonly />
                    </label>
                  </div>
                </dd>
              </dl>
              <div class="btn_wrap" style="margin-top: 1rem;">
                <button class="btn" @click="fnModalAddInfo">
                  {{
                    $Util.isEmpty(party.reply)
                      ? '추가 정보 등록'
                      : '추가 정보 편집'
                  }}
                </button>
              </div>
              <div class="hint">
                * 파티장 요청사항이 있는 경우 반드시 정보 입력을 해주세요.
              </div>
            </div>
          </div>
          <!--         20210818 주의사항주석 처리 -->
          <!--          <div-->
          <!--            class="lump"-->
          <!--            style="border: 0;"-->
          <!--            v-if="!$Util.isEmpty(party.precautionRegDt)"-->
          <!--          >-->
          <!--            <div class="table_type02">-->
          <!--              <dl>-->
          <!--                <dt>-->
          <!--                  주의사항-->
          <!--                  <span-->
          <!--                    class="table_date"-->
          <!--                    :class="{ new: Number(party.hasPrecautionsBadge) === 1 }"-->
          <!--                    >{{-->
          <!--                      $dayjs(party.precautionRegDt).format('YYYY.MM.DD')-->
          <!--                    }}</span-->
          <!--                  >-->
          <!--                </dt>-->
          <!--                <dd class="editor">-->
          <!--                  <div v-html="party.precautions"></div>-->
          <!--                </dd>-->
          <!--              </dl>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="status" v-for="(item, index) in conflict" :key="index">
            <div class="inner">
              <dl class="status_sec">
                <dt class="status_ttl">
                  취소/환불 신청
                  <span
                    class="status_date"
                    :class="{
                      new:
                        Number(item.hasEscrowBadge) === 1 ||
                        Number(item.modi) === 1
                    }"
                    >{{ $dayjs(item.regDt).format('YYYY.MM.DD') }}</span
                  >
                </dt>
                <dd class="status_main">
                  <div class="status_sec_ttl">
                    <div class="reason">
                      <span class="sec_color">{{
                        Number(item.option) === 0 ? '단순 변심' : '파티장 과실'
                      }}</span>
                    </div>
                    <button
                      class="btn_revise"
                      @click="fnRefund($ConstCode.BOOLEAN_VALUE.TRUE, item)"
                      v-if="item.status === '0'"
                    ></button>
                  </div>
                  <div class="editor">
                    <div v-html="item.msg"></div>
                  </div>
                </dd>
                <dd class="status_sub">
                  <div>
                    · 취소 환불 신청은 파티장이 3일 이내에 취소 승인 또는 거부
                    의사를 밝힙니다.
                  </div>
                  <div>
                    · 파티장 승인에 따라 취소 승인이 나면, 사용건을 제외한
                    비용에 대해 카드 부분 취소가 진행되며, 파티장 승인일로부터
                    영업일 기준 1~2일 소요 될 수 있습니다.
                  </div>
                  <div>
                    · 단, 결제로부터 당일 미사용 처리 된 항목은 결제취소/ 환불
                    되며, 파티장 승인일로부터 영업일 기준 1~2일 소요될 수 있습
                    니다.
                  </div>
                </dd>
              </dl>

              <dl class="status_sec" v-if="item.status === '1'">
                <dt class="status_ttl">
                  취소/환불 안내
                  <span
                    class="status_date"
                    :class="{ new: Number(item.replyModi) === 1 }"
                    >{{ $dayjs(item.replyRegDt).format('YYYY.MM.DD') }}</span
                  >
                </dt>
                <dd class="status_main">
                  <div class="status_sec_ttl">
                    <div class="reason">
                      <span class="color">승인 완료</span>
                    </div>
                  </div>
                  <div class="editor">
                    <div>
                      {{
                        `파티장(자동)승인에 따라, 고객님께서 ${$dayjs(
                          item.regDt
                        ).format(
                          'YYYY년 MM.DD일'
                        )}일 문의하신 환불 요청건은 확인 결과 취소처리하였습니다.
                              자세한 사항은 개인카드 결제 내역, 캐시/포인트 내역을 확인해주세요.`
                      }}
                    </div>
                  </div>
                </dd>
              </dl>
              <dl class="status_sec" v-else-if="item.status === '2'">
                <dt class="status_ttl">
                  취소/환불 안내
                  <span
                    class="status_date"
                    :class="{ new: Number(item.replyModi) === 1 }"
                    >{{ $dayjs(item.replyRegDt).format('YYYY.MM.DD') }}</span
                  >
                </dt>
                <dd class="status_main">
                  <div class="status_sec_ttl">
                    <div class="reason">
                      <span class="error">파티장 반려</span>
                    </div>
                  </div>
                  <div class="editor">
                    <div v-html="item.reply"></div>
                  </div>
                </dd>
                <dd class="status_sub">
                  <div>
                    · 파티장의 반려 처리 후 추가 이의는 3일 이내에 진행해 주십
                    시오.
                  </div>
                  <div>
                    · 3일이 지나면 자동 결제 승인됩니다.
                  </div>
                </dd>
              </dl>
            </div>
            <div class="lump bo_zero" v-if="item.status === '3'">
              <div class="table_type02">
                <dl>
                  <dt>
                    분쟁 취소 안내
                    <span
                      class="table_date"
                      :class="{ new: Number(item.replyModi) === 1 }"
                      >{{ $dayjs(item.replyRegDt).format('YYYY.MM.DD') }}</span
                    >
                  </dt>
                  <dd class="editor">
                    <div>
                      <!--붙여쓰기-->취소 환불 신청이 취소되었습니다.<!--붙여쓰기-->
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>

          <div class="con_btn">
            <div class="btn_wrap">
              <button
                class="btn bo"
                @click="fnWizzTalk"
                v-if="
                  party.wizztalkYn === 'Y' &&
                    !approval &&
                    !(
                      party.statusNm === '취소/환불' ||
                      party.statusNm === '종료'
                    )
                "
              >
                위즈톡<span class="wiz_icon"></span>
              </button>
              <button
                class="btn bo"
                v-if="
                  isShowRefund &&
                    !(
                      party.statusNm === '취소/환불' ||
                      party.statusNm === '종료'
                    )
                "
                @click="fnRefund($ConstCode.BOOLEAN_VALUE.FALSE)"
              >
                취소/환불
              </button>
              <button
                class="btn bo"
                v-if="
                  isShowCancelRefund &&
                    !(
                      party.statusNm === '취소/환불' ||
                      party.statusNm === '종료'
                    )
                "
                @click="cancelRefund"
              >
                분쟁 취소
              </button>
              <button
                class="btn bo"
                v-if="
                  party.statusNm === '취소/환불' || party.statusNm === '종료'
                "
                @click="fnDelete"
              >
                파티 삭제
              </button>
            </div>
            <div
              class="btn_wrap"
              v-if="
                party.acntTransferYn === 'Y' &&
                  !approval &&
                  !(
                    party.statusNm === '취소/환불' || party.statusNm === '종료'
                  ) &&
                  !isShowCancelRefund
              "
            >
              <button class="btn bo" @click="fnApply">결제 승인</button>
            </div>
          </div>
          <div class="caution">
            <div class="inner">
              <dl>
                <dt>주의사항</dt>
                <dd>
                  <!--210713삭제-->
                  <!--                                <div>* 위즈니는 사용자간의 컨텐츠 비용을 나눔을 할 수 있도록 지원해드리고 있으며, 참여하신 서비스의 주체가 아닙니다.</div>-->
                  <div>
                    * 개별 공급자가 등록한 나눔 내용 및 거래에 대한 책임은
                    공급자가 부담하며, 이에 따라서 공급자가 진행하는 서비스에
                    대해서 위즈니는 어떠한 책임과 의무를 지지 않습니다.
                  </div>
                  <div>
                    * 파티장 연락두절 및 이용 불가능한 상태 방치 등에 의한
                    환불인 경우 위즈니에서 남은 기간에 대한 환불을 보장하며,
                    위즈캐시로 환불 진행됩니다.
                  </div>
                  <div>
                    (단 참여 후 1일이 경과되지 않은 상태에서 파티장 과실에 의한
                    사용 불가가 확인 된 경우 100%금액 현금 환불)
                  </div>
                  <div class="sec_color">
                    * 양도 거래시에 발생되는 분쟁은 당사자간에 해결이 필요하며,
                    위즈니는 책임을 지지 않습니다. 반드시 계정 양도에 적합한
                    상품인지 확인하고 거래해주세요.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-page-joined-party-view-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
